@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

:root {
  --dark-blue: #2C2E43;
  --first-yellow: #E4C865;
  --dark-gray: #707070;
  --light-gray: #F6F7F8;
  --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  background-color: var(--dark-blue);
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  
  display: grid;
}

.darkmode__lightmode__switch {
  position: fixed !important;
  background-color: transparent;
  color: #fff;
  right: 5%;
  top: 1%;
  font-size: 28px;
  z-index: 9;
}

/* === scrollbar style === */
/* === width === */
::-webkit-scrollbar {
  width: 10px;
}

/* === Track === */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--first-yellow); 
  border-radius: 40px;
}
 
/* === Handle === */
::-webkit-scrollbar-thumb {
  background: var(--dark-blue); 
  border-radius: 40px;
}

/* === Handle on hover === */
::-webkit-scrollbar-thumb:hover {
  background:var(--dark-gray); 
}
/* === scrollbar style end here === */

.header__middle__container {
  width: 100%;
	margin-right:auto;
	margin-left:auto
}

.header__content {
  padding-left: 15px;
  padding-right: 15px;
}

img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #a3914f!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-family: var(--primary-font);
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: var(--first-yellow);
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}

.top__navbar {
  width: 100%;
  background-color: var(--first-yellow);
  z-index: 1;
}

.top__navbar__menu {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.top__nav__menu__left {
  display: flex;
}

.top__navbar__menu li {
  padding: 10px;
  padding-bottom: 0px;
}


.header__middle__logo{
  padding-left: 55px;
  width: 20%;
  display: inline-block;
  float: left;
}
.header__middle__menus {
  width: 70%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  background-color: var(--dark-blue);
  display: flex;
  width: 100%;
  float: left;
  position: sticky;
  top: 0;
  z-index: 7;
  transition: all 0.5s ease-in;
}

.header__middle.active {
  background-color: rgba(33, 35, 51, 0.295);
  top: -20px;
  transition: all 0.5s ease;
}

.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  z-index: 3;
  display: none;
  background-color: #fff;
  border: 1px solid transparent;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 120px;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 45%);
}


/* === style change web language button === */
.change__web__lang {
  background-color: var(--first-yellow);
  border: none;
}


@media (max-width:767px) { 
  body {
    width: 100% !important;
    overflow-x: hidden !important;
  }

  .header__middle .active {color: var(--first-yellow) !important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: #f7e090 !important;
}
.menu-item .sub__menus li:hover a{
  color: #f7e090 !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){

  .darkmode__lightmode__switch {
    position: fixed !important;
    background-color: transparent;
    color: #fff;
    left: 0%;
    right: 85%;
    top: 1%;
    font-size: 28px;
    z-index: 9;
  }

.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: var(--first-yellow); transition: all 0.3s ease;}
.main-nav .menubar__button {transition: all 0.5s ease;}
.main-nav .menu-item:hover > a {color: #f5dc81 !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: var(--first-yellow);
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}

.sub__menus {
  z-index: 3 !important;
}

.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: var(--dark-blue);
  border-bottom: 3px solid var(--dark-gray);
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: var(--first-yellow);}
.main-nav  ul.main-menu {display: none;}
.main-nav .sub__menus__arrows:hover .sub__menus { margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}