:root {
  --dark-blue: #2C2E43;
  --first-yellow: #E4C865;
  --dark-gray: #707070;
  --light-gray: #F6F7F8;
  --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* 3 service card style */
.services__cards {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -4%;
    position: relative;
    z-index: 3;
  }
  
  .services__cards .card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 4%;
    margin-right: 4%;
    width: 500px;
    border-radius: 40px;
    border: none;
    -webkit-box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.4); 
    box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
    transition: all 0.5s ease !important;
  }
  
  .services__cards .card:hover {
    transform: translateY(-22px);
    transition: all 0.5s ease;
  }
  
  .services__cards .card:after,
  .services__cards .card:before {
    top: 0;
    transition: all 0.5s ease !important;
  }
  
  .services__cards .card__content {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
  }
  
  .services__cards .card__content .card__dontent__description {
    margin-left: 25px;
  }
  
  .services__cards .card__content h1 {
    font-family: var(--primary-font);
    border: 1px solid var(--first-yellow);
    clip-path: circle(40%);
    padding: 10px;
    background-color: var(--first-yellow);
    color: var(--dark-gray);
    text-align: center;
  }
  .services__cards .card__content p {
    font-family: var(--description-font);
    font-weight: normal;
  }

  .link__to__card {
    color: var(--dark-blue);
  }

  .link__to__card:hover {
    text-decoration: none;
    color: var(--dark-gray);
  }

  /* 3 service card styule end here */
  

  /* style for smaller devices */

  @media(max-width:991px) {

    .services__cards {
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    .services__cards .card {
      margin-top: -20px;
      width: 280px;
      margin-top: 20px;
    }

    .our__services .it__services__section img {
      margin-bottom: 50px;
    }

  }

  /* style for smaller devices end here */