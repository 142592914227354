:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  /* === About us page style start here === */
  
  .about__us__description {
      padding-top: 200px;
      margin-bottom: 100px;
  }

  .about__us__description .aboutus__desc__block {
      padding: 20px;
      border-radius: 40px;
      background-color: var(--light-gray);
      -webkit-box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.4); 
      box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
  }

  .about__us__description h3 {
      display: flex;
      justify-content: space-around;
      align-content: center;
      margin-bottom: 30px;
      font-size: 48px;
      font-family: var(--primary-font);
  }

  .about__us__description p {
      font-family: var(--description-font);
      font-size: 20px;
      letter-spacing: 1px;
      word-spacing: 3px;
  }

  .about__us__description .aboutus___images {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
  }

  .about__us__description .aboutus___images img {
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 400px;
      transition: all 1s ease;
  }

  .about__us__description .aboutus___images img:hover {
    cursor: crosshair;
      border-radius: 10px;
    transition: all 0.5s ease;
  }

  .aboutus__decoration {
    position: absolute;
    z-index: -1;
    height: 350px;
    width: 350px;
    border-radius: 100px;
    background-image: linear-gradient(to right, var(--dark-blue) , var(--light-gray));
    transform: rotate(45deg);
    /* transform-origin: 0 100%; */
  }

  .aboutus__decoration__2 {
    position: relative;
    float: right;
    margin-top: -200px;
    z-index: -1;
    height: 350px;
    width: 350px;
    border-radius: 100px;
    background-image: linear-gradient(to right, var(--light-gray) , var(--first-yellow));
    transform: rotate(45deg);
    /* transform-origin: 0 100%; */
  }

  /* === Abotu us page style end here === */

  @media (max-width:767px) {
    .about__us__description .aboutus___images {
        width: 300px !important;
        margin-left: 25px;
        margin-right: 25px;
    }
  }