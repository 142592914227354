:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

/* It services style */

.our__services {
    margin-top: 150px;
    display: grid;
    justify-content: space-around;
    align-content: center;
}

.our__services .it__services__section {
    display: flex;
}

.our__services .it__services__tittle {
    display: flex;
    justify-content: center;
    text-align: center;
}

.our__services .it__services__tittle h3 {
    font-family: var(--primary-font);
    font-size: 40px;
    padding-bottom: 20px;
}

.our__services .it__services__description li {
    font-family: var(--description-font);
}

.our__services .it__services__section /*.call__center__section*/ {
    margin-top: 140px;
}

.our__services .it__services__description i {
    color: var(--dark-gray);
}

.call__center__section .call__center__tittle,
.our__services .it__services__tittle,
.call__center__section .call__center__description li,
.our__services .it__services__description li:hover {
    cursor: context-menu;
}

.call__center__section .call__center__tittle {
    display: flex;
    justify-content: center;
    text-align: center;
}

.call__center__section .call__center__tittle h3 {
    font-family: var(--primary-font);
    font-size: 40px;
    padding-bottom: 20px;
}

.call__center__section .call__center__description i {
    color: var(--dark-gray);
}

/* it services sytle end here */