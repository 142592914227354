:root {
  --dark-blue: #2C2E43;
  --first-yellow: #E4C865;
  --dark-gray: #707070;
  --light-gray: #F6F7F8;
  --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* About us section style */
.about__us__section {
    margin-top: 100px;
    background-color: var(--light-gray);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .about__us__section h3 {
    font-family: var(--primary-font);
    font-size: 45px;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: space-around;
    text-align: center;
  }
  
  .about__us__section p {
    margin-top: 10px;
    font-family: var(--description-font);
  }
  
  .red__more__about__us__section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about__us__section .read__more__aboutus {
    width: 250px;
    display: flex;
    margin-top: 20px;
    font-weight: 500;
    background-color: var(--first-yellow);
    border-radius: 40px;
    border: 4px solid transparent;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.4); 
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.21);
    transition: all .2s ease-in !important;
  }
  
  .about__us__section .read__more__aboutus:hover{
    background-color: #fff;
    color: var(--dark-blue);
    border: 4px dotted var(--first-yellow);
    transition: all .2s ease-in !important;
  }
  
  .about__us__section .aboutus__img {
    border: none !important;
    clip-path: circle(30%);
    transition: all 0.5s ease;
    -webkit-box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.4); 
    box-shadow: 10px 10px 10px 5px rgba(0,0,0,0.21);
  }

  .about__us__section .aboutus__img:hover{
    clip-path: circle(60%);
    transform: scale(1.2);
    margin-left: -50%;
    transition: all 0.5s ease;
    cursor: crosshair;
  } 
  
  /* About us section style end here */

  /* About us style for smaller device */
  @media(max-width:991px) {

    .about__us__section .aboutus__img:hover{
      transform: scale(0.7);
      margin-left: 0%;
    }

  }
  
  /* About us style for smaller device end here */