:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  /* shape decoration style start here */
  .moon {
    position: absolute;
    width: 28%;
    height: 66%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 var(--first-yellow);
  }
  .moon2 {
    position: absolute;
    width: 25%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 var(--dark-blue);
  }
  .moon3 {
    position: absolute;
    width: 35%;
    height: 64%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 var(--dark-gray);
  }
  .moon4 {
    position: absolute;
    width: 45%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 11px 0 0 var(--light-gray);
  }
  .moon5 {
    position: absolute;
    width: 55%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 18px 10px 0 0 var(--first-yellow);
  }
  .moon6 {
    position: absolute;
    top: 36%;
    width: 65%;
    height: 92%;
    border-radius: 50%;
    box-shadow: 15px 10px 0 0 var(--dark-gray);
  }
  .moon7 {
    position: absolute;
    width: 75%;
    height: 72%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 var(--light-gray);
  }
  .moon8 {
    position: absolute;
    width: 85%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 11px 15px 0 0 var(--dark-blue);
  }
  .moon9 {
    position: absolute;
    top: 25%;
    width: 95%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 19px 5px 0 0 var(--first-yellow);
  }
  .moon10 {
    position: absolute;
    top: 34%;
    width: 45%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 14px 20px 0 0 var(--dark-blue);
  }
/* Shape decoration style end here */
  .contact__us__page .contactus__tittle {
    margin-top: 80px;
    font-family: var(--primary-font);
  }

  .contact__us__page .contactus__tittle h3 {
    font-size: 32px;
  }

  .contact__us__page .number__contact, 
  .contact__us__page .email__contact {
    margin-top: 20px;
    background-color: var(--first-yellow);
    color:var(--dark-blue);
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 20px;
  }
 
  .MuiOutlinedInput-input {
    padding: 10px 10px !important;
  }

  .MuiPaper-elevation1 {
    border-radius: 30px !important;
    box-shadow: 0px 10px 10px 5px rgb(0 0 0 / 20%), 0px 0px 10px 5px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  }

  .container.contact__form__top_content {
    width: 60%;
  }

  .contact__us__page .contact__form {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 60%;
  }
  
  .contact__us__page .online__tittle {
    margin-top: 60px;
  }

  .contact__us__page .contact__form input {
    font-family: var(--description-font);
    font-weight: 600;
    /* background-color: var(--light-gray);
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 40px;
    width: 60%; */
    color: var(--dark-blue);
  }

  .contact__us__page .contact__form select {
    font-family: var(--description-font);
    border: 1px solid var(--dark-gray);
    font-weight: 600;
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    display: flex;
    color: var(--dark-blue);
  }
  
  .contact__us__page .contact__form textarea {
    font-family: var(--primary-font);
    font-weight: 600;
    /* background-color: var(--light-gray);
    padding: 12px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 20px;
    width: 60%; */
    color: var(--dark-blue);
  }

  .contact__us__page .apply__button {
    font-size: 22px;
    background-color: var(--first-yellow);
    border-radius: 20px;
    transition: all 0.5s ease;
  }

  .contact__us__page .apply__button:hover {
    opacity: 0.8;
    transition: all 0.5s ease;
  }

  .octagon {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px; 
    height: 50%; 
    background:var(--first-yellow);
 }
  .octagon2 {
    position: absolute ;
    right: 0;
    bottom: -20%;
    width: 80px; 
    height: 50%; 
    background:var(--dark-blue);
 }

 @media(max-width:991px){
 
  .octagon {
    display: none;
 }
  .octagon2 {
    display: none;
 }

 .container.contact__form__top_content {
  width: 100% !important;
}

.contact__us__page .contact__form {
  width: 100%;
}
  
}