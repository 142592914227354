:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .gallery {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
    transition: all 0.5s ease;
  }

  .gallery .pics{
    -webkit-transition: all 0.5s ease;
    cursor: zoom-in;
    margin-bottom: 12px;
    transition: all 0.5s ease;
  }

  .gallery img {
    border-radius: 30px;
    box-shadow: 0 10px 12px rgb(22 33 39 / 35%);
  }

  .gallery .pics:hover {
    opacity: .8;
  }

  
  .triangle-topleft {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 100rem solid var(--first-yellow);
    z-index: -1;
    overflow: hidden;
    border-right: 400px solid transparent;
  }
  .triangle-topleft2 {
    position: absolute;
    width: 0;
    height: 0;
    right: 0 !important;
    transform: rotate(180deg);
    border-top: 100rem solid var(--dark-blue);
    z-index: -1;
    overflow: hidden;
    border-right: 400px solid transparent;
  }

  /* model style start here */

  .model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, var(--dark-blue) , var(--light-gray) , var(--first-yellow));
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
  }

  .model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  .model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
  }

  .model .close__model {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: var(--dark-blue);
    border: none;
    color: var(--light-gray);
    cursor: pointer;
    transition: all 0.5s ease-in;
  }

  .model .close__model:hover {
    background-color: var(--first-yellow);
    transition: all 0.5s ease;
  }

  /* model style end here */

  @media (max-width: 991px) {
    .gallery {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      transition: all 0.5s ease;
    }
  }

  @media (max-width: 480px) {
    .gallery {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
      -moz-column-width: 100%;
      column-width: 100%;
    }
  }