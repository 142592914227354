:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

/* footer style */

.as__footer {
    background-color: var(--dark-blue);
    width: 100%;
    height: 270px !important;
    position: relative !important;
    bottom: 0 !important;
    min-height: 100%;
}

.as__footer .footer__content .footer_adress,
.footer_contact,
.footer_menu,
.footer_sub__menu,
.footer_sub__adress,
.footer_sub__email {
    color: var(--first-yellow);
}

.as__footer .footer__content {
    position: absolute !important;
    bottom: 0 !important;
}

.as__footer .footer__content .social__icon {
    color: var(--first-yellow);
    display: inline-flex;
    float: left;
    padding: 10px;
    font-size: 20px;
    justify-content: space-around;
    align-items: center;
}

.as__footer .footer__content .social__icon:hover {
    cursor: pointer;
}

.copy__right h6 {
    color: var(--first-yellow);
    position: relative;
    bottom: 0;
}

.as__footer .whatsapp__icon__footer i {
    display: flex;
    justify-content: right;
    float: right;
    margin: 50px;
    color: #F6F7F8;
    background-color: rgb(154, 205, 50);
    padding: 20px;
    clip-path: circle(40%);
    font-size: 38px;
    transition: all 0.5s ease;
}

.as__footer .whatsapp__icon__footer i:hover {
    transform: rotate(25deg);
    background-color: rgb(127, 168, 43);
    transition: all 0.5s ease;
}

/* footer style end here */
@media(max-width:991px){
 
    .as__footer {
        height: 600px !important;
    }
    
  }