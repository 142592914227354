:root {
  --dark-blue: #2C2E43;
  --first-yellow: #E4C865;
  --dark-gray: #707070;
  --light-gray: #F6F7F8;
  --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* === carousel text style === */
.header__carousel {
    position: relative;
    z-index: 1;
    display: block;
  }
  
  .header__carousel img {
    width: 100%;
    height: auto;
  }
  
  .carousel__caption__text h3{
    font-family: var(--primary-font);
    font-size: 45px;
    font-weight: 700;
  }
  
  .carousel__caption__text p {
    font-family: var(--description-font);
    font-size: 22px;
  }
  
  .carousel__caption__text h3, p  {
    text-align: left;
    justify-content: space-around;
    align-items: center;
  }
  
  .carousel__caption__text {
    top: 20%;
  }

  .overlay {
    font-family: var(--primary-font);
    position:absolute;
    top:25%;
    left:10%;
    right:10%;
    z-index:1;
    font-size: 60px;
    color: #fff;
}

  /* === carousel style end here === */


  /* === style for smaller devices === */
  @media(max-width:991px) {

    .overlay {
      margin-top: 5%;
      font-size: 36px;
    }

  }

  @media (max-width:767px) {
    .overlay {
      display: none;
    } 
  }