@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
.clearfix{
  clear:both;
}
a{
  text-decoration:none;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
a:hover{
  text-decoration:none;
  outline:none;
}


:root {
  --dark-blue: #2C2E43;
  --first-yellow: #E4C865;
  --dark-gray: #707070;
  --light-gray: #F6F7F8;
  --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* === carousel text style === */
.header__carousel {
    position: relative;
    z-index: 1;
    display: block;
  }
  
  .header__carousel img {
    width: 100%;
    height: auto;
  }
  
  .carousel__caption__text h3{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    font-size: 45px;
    font-weight: 700;
  }
  
  .carousel__caption__text p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
    font-size: 22px;
  }
  
  .carousel__caption__text h3, p  {
    text-align: left;
    justify-content: space-around;
    align-items: center;
  }
  
  .carousel__caption__text {
    top: 20%;
  }

  .overlay {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    position:absolute;
    top:25%;
    left:10%;
    right:10%;
    z-index:1;
    font-size: 60px;
    color: #fff;
}

  /* === carousel style end here === */


  /* === style for smaller devices === */
  @media(max-width:991px) {

    .overlay {
      margin-top: 5%;
      font-size: 36px;
    }

  }

  @media (max-width:767px) {
    .overlay {
      display: none;
    } 
  }
:root {
  --dark-blue: #2C2E43;
  --first-yellow: #E4C865;
  --dark-gray: #707070;
  --light-gray: #F6F7F8;
  --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* 3 service card style */
.services__cards {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -4%;
    position: relative;
    z-index: 3;
  }
  
  .services__cards .card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 4%;
    margin-right: 4%;
    width: 500px;
    border-radius: 40px;
    border: none; 
    box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
    transition: all 0.5s ease !important;
  }
  
  .services__cards .card:hover {
    -webkit-transform: translateY(-22px);
            transform: translateY(-22px);
    transition: all 0.5s ease;
  }
  
  .services__cards .card:after,
  .services__cards .card:before {
    top: 0;
    transition: all 0.5s ease !important;
  }
  
  .services__cards .card__content {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
  }
  
  .services__cards .card__content .card__dontent__description {
    margin-left: 25px;
  }
  
  .services__cards .card__content h1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    border: 1px solid #E4C865;
    border: 1px solid var(--first-yellow);
    -webkit-clip-path: circle(40%);
            clip-path: circle(40%);
    padding: 10px;
    background-color: #E4C865;
    background-color: var(--first-yellow);
    color: #707070;
    color: var(--dark-gray);
    text-align: center;
  }
  .services__cards .card__content p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
    font-weight: normal;
  }

  .link__to__card {
    color: #2C2E43;
    color: var(--dark-blue);
  }

  .link__to__card:hover {
    text-decoration: none;
    color: #707070;
    color: var(--dark-gray);
  }

  /* 3 service card styule end here */
  

  /* style for smaller devices */

  @media(max-width:991px) {

    .services__cards {
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    .services__cards .card {
      margin-top: -20px;
      width: 280px;
      margin-top: 20px;
    }

    .our__services .it__services__section img {
      margin-bottom: 50px;
    }

  }

  /* style for smaller devices end here */
:root {
  --dark-blue: #2C2E43;
  --first-yellow: #E4C865;
  --dark-gray: #707070;
  --light-gray: #F6F7F8;
  --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* About us section style */
.about__us__section {
    margin-top: 100px;
    background-color: #F6F7F8;
    background-color: var(--light-gray);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .about__us__section h3 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    font-size: 45px;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: space-around;
    text-align: center;
  }
  
  .about__us__section p {
    margin-top: 10px;
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
  }
  
  .red__more__about__us__section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about__us__section .read__more__aboutus {
    width: 250px;
    display: flex;
    margin-top: 20px;
    font-weight: 500;
    background-color: #E4C865;
    background-color: var(--first-yellow);
    border-radius: 40px;
    border: 4px solid transparent;
    justify-content: center;
    align-items: center; 
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.21);
    transition: all .2s ease-in !important;
  }
  
  .about__us__section .read__more__aboutus:hover{
    background-color: #fff;
    color: #2C2E43;
    color: var(--dark-blue);
    border: 4px dotted #E4C865;
    border: 4px dotted var(--first-yellow);
    transition: all .2s ease-in !important;
  }
  
  .about__us__section .aboutus__img {
    border: none !important;
    -webkit-clip-path: circle(30%);
            clip-path: circle(30%);
    transition: all 0.5s ease; 
    box-shadow: 10px 10px 10px 5px rgba(0,0,0,0.21);
  }

  .about__us__section .aboutus__img:hover{
    -webkit-clip-path: circle(60%);
            clip-path: circle(60%);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    margin-left: -50%;
    transition: all 0.5s ease;
    cursor: crosshair;
  } 
  
  /* About us section style end here */

  /* About us style for smaller device */
  @media(max-width:991px) {

    .about__us__section .aboutus__img:hover{
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
      margin-left: 0%;
    }

  }
  
  /* About us style for smaller device end here */
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

/* It services style */

.our__services {
    margin-top: 150px;
    display: grid;
    justify-content: space-around;
    align-content: center;
}

.our__services .it__services__section {
    display: flex;
}

.our__services .it__services__tittle {
    display: flex;
    justify-content: center;
    text-align: center;
}

.our__services .it__services__tittle h3 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    font-size: 40px;
    padding-bottom: 20px;
}

.our__services .it__services__description li {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
}

.our__services .it__services__section /*.call__center__section*/ {
    margin-top: 140px;
}

.our__services .it__services__description i {
    color: #707070;
    color: var(--dark-gray);
}

.call__center__section .call__center__tittle,
.our__services .it__services__tittle,
.call__center__section .call__center__description li,
.our__services .it__services__description li:hover {
    cursor: context-menu;
}

.call__center__section .call__center__tittle {
    display: flex;
    justify-content: center;
    text-align: center;
}

.call__center__section .call__center__tittle h3 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    font-size: 40px;
    padding-bottom: 20px;
}

.call__center__section .call__center__description i {
    color: #707070;
    color: var(--dark-gray);
}

/* it services sytle end here */
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

/* why chose us  style */

.why__us {
    margin-top: 80px;
    background-color: #F6F7F8;
    background-color: var(--light-gray);
}

.why__us .why__us__tittle {
    padding: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.why__us .why__us__tittle h3 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    font-size: 40px;
}

.why__us .why__us__description {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
    padding-bottom: 20px;
}

.goto__leadership {
    padding-bottom: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.goto__leadership__btn {
    border: 4px solid transparent;
    width: 250px;
    border-radius: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    color: #F6F7F8;
    color: var(--light-gray);
    background-color: #E4C865;
    background-color: var(--first-yellow);
    display: flex;
    justify-content: center;
    text-align: center;
    transition: all .2s ease-in !important;
}

.goto__leadership__btn:hover{
    border: 4px dotted #E4C865;
    border: 4px dotted var(--first-yellow);
    background-color: #F6F7F8;
    background-color: var(--light-gray);
    transition: all .2s ease-in !important;
}

/* why chose us style end here */
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

/* footer style */

.as__footer {
    background-color: #2C2E43;
    background-color: var(--dark-blue);
    width: 100%;
    height: 270px !important;
    position: relative !important;
    bottom: 0 !important;
    min-height: 100%;
}

.as__footer .footer__content .footer_adress,
.footer_contact,
.footer_menu,
.footer_sub__menu,
.footer_sub__adress,
.footer_sub__email {
    color: #E4C865;
    color: var(--first-yellow);
}

.as__footer .footer__content {
    position: absolute !important;
    bottom: 0 !important;
}

.as__footer .footer__content .social__icon {
    color: #E4C865;
    color: var(--first-yellow);
    display: inline-flex;
    float: left;
    padding: 10px;
    font-size: 20px;
    justify-content: space-around;
    align-items: center;
}

.as__footer .footer__content .social__icon:hover {
    cursor: pointer;
}

.copy__right h6 {
    color: #E4C865;
    color: var(--first-yellow);
    position: relative;
    bottom: 0;
}

.as__footer .whatsapp__icon__footer i {
    display: flex;
    justify-content: right;
    float: right;
    margin: 50px;
    color: #F6F7F8;
    background-color: rgb(154, 205, 50);
    padding: 20px;
    -webkit-clip-path: circle(40%);
            clip-path: circle(40%);
    font-size: 38px;
    transition: all 0.5s ease;
}

.as__footer .whatsapp__icon__footer i:hover {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
    background-color: rgb(127, 168, 43);
    transition: all 0.5s ease;
}

/* footer style end here */
@media(max-width:991px){
 
    .as__footer {
        height: 600px !important;
    }
    
  }
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  }

/* It service page style start here */

  .it__services {
      background-color: #F6F7F8;
      background-color: var(--light-gray);
      width: 100%;
      height: auto;
  }

  .it__services .is__services__card .card {
      margin-top: 80px;
      margin-bottom: 80px;
      border-radius: 30px; 
     box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
    transition: all 0.5s ease;    
}

  .it__services .is__services__card .card__img {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 30px;
      border: 1px solid transparent;
      border-right: 4px dotted #E4C865;
      border-right: 4px dotted var(--first-yellow);
    }

  .it__services .is__services__card .card__tittle {
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-family: var(--primary-font);
      color: #2C2E43;
      color: var(--dark-blue);
      font-size: 32px;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
  }

  .it__services .is__services__card .card__description {
      font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
      font-family: var(--description-font);
      color: #707070;
      color: var(--dark-gray);
      font-size: 18px;
      padding: 10px;
  }

  /* get service button style */ 
  .order__btn {
    --offset: 10px;
    --border-size: 2px;
    display: block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    position: relative;
    /* padding: 1.5em 3em; */
    -webkit-appearance: none;
            appearance: none;
    border: 0;
    background: transparent;
    color: #2C2E43 !important;
    color: var(--dark-blue) !important;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 0;
    transition: background 0.8s ease;
  }
  .order__btn:hover {
    background: #E4C865;
    background: var(--first-yellow);
  }
  .button__horizontal, .button__vertical {
    position: absolute;
    top: 0;
    top: var(--horizontal-offset, 0);
    right: 0;
    right: var(--vertical-offset, 0);
    bottom: 0;
    bottom: var(--horizontal-offset, 0);
    left: 0;
    left: var(--vertical-offset, 0);
    transition: -webkit-transform 0.8s ease;
    transition: transform 0.8s ease;
    transition: transform 0.8s ease, -webkit-transform 0.8s ease;
    will-change: transform;
  }
  .button__horizontal::before, .button__vertical::before {
    content: "";
    position: absolute;
    border: inherit;
  }
  .button__horizontal {
    --vertical-offset: calc(var(--offset) * -1);
    border-top: var(--border-size) solid currentcolor;
    border-bottom: var(--border-size) solid currentcolor;
  }
  .button__horizontal::before {
    top: calc(var(--vertical-offset) - var(--border-size));
    bottom: calc(var(--vertical-offset) - var(--border-size));
    left: calc(var(--vertical-offset) * -1);
    right: calc(var(--vertical-offset) * -1);
  }
  .order__btn:hover .button__horizontal {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  .button__vertical {
    --horizontal-offset: calc(var(--offset) * -1);
    border-left: var(--border-size) solid currentcolor;
    border-right: var(--border-size) solid currentcolor;
  }
  .button__vertical::before {
    top: calc(var(--horizontal-offset) * -1);
    bottom: calc(var(--horizontal-offset) * -1);
    left: calc(var(--horizontal-offset) - var(--border-size));
    right: calc(var(--horizontal-offset) - var(--border-size));
  }
  .order__btn:hover .button__vertical {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
/* Get service button style end here */

/* shape decoration style start here */
  .moon {
    position: absolute;
    width: 28%;
    height: 66%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #E4C865;
    box-shadow: 15px 15px 0 0 var(--first-yellow);
  }
  .moon2 {
    position: absolute;
    width: 25%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #2C2E43;
    box-shadow: 15px 15px 0 0 var(--dark-blue);
  }
  .moon3 {
    position: absolute;
    width: 35%;
    height: 64%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #707070;
    box-shadow: 15px 15px 0 0 var(--dark-gray);
  }
  .moon4 {
    position: absolute;
    width: 45%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 11px 0 0 #F6F7F8;
    box-shadow: 15px 11px 0 0 var(--light-gray);
  }
  .moon5 {
    position: absolute;
    width: 55%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 18px 10px 0 0 #E4C865;
    box-shadow: 18px 10px 0 0 var(--first-yellow);
  }
  .moon6 {
    position: absolute;
    top: 36%;
    width: 65%;
    height: 92%;
    border-radius: 50%;
    box-shadow: 15px 10px 0 0 #707070;
    box-shadow: 15px 10px 0 0 var(--dark-gray);
  }
  .moon7 {
    position: absolute;
    width: 75%;
    height: 72%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #F6F7F8;
    box-shadow: 15px 15px 0 0 var(--light-gray);
  }
  .moon8 {
    position: absolute;
    width: 85%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 11px 15px 0 0 #2C2E43;
    box-shadow: 11px 15px 0 0 var(--dark-blue);
  }
  .moon9 {
    position: absolute;
    top: 25%;
    width: 95%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 19px 5px 0 0 #E4C865;
    box-shadow: 19px 5px 0 0 var(--first-yellow);
  }
  .moon10 {
    position: absolute;
    top: 34%;
    width: 45%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 14px 20px 0 0 #2C2E43;
    box-shadow: 14px 20px 0 0 var(--dark-blue);
  }
/* Shape decoration style end here */


/* It service page style end here */

/* style for smnaller device */

@media(max-width:991px){

    .it__services .is__services__card .card__img {
        border-bottom: 4px dotted transparent;
        border-right: 4px dotted transparent;
      }

}

@media(max-width:767px){

    .it__services .is__services__card .card__img {
        border-bottom: 4px dotted #E4C865;
        border-bottom: 4px dotted var(--first-yellow);
        border-right: 4px dotted transparent;
        border-radius: 40px;
        transition: all 0.5 ease;
      }
    
}

/* style for smaller device end here */
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* === It service page style start here === */

  .call__center__page {
      background-color: #F6F7F8;
      background-color: var(--light-gray);
      width: 100%;
      height: auto;
  }

  .call__center__page .call__center__card .card {
      margin-top: 80px;
      margin-bottom: 80px;
      border-radius: 30px; 
     box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
    transition: all 0.5s ease;    
}

  .call__center__page .call__center__card .card__img {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 30px;
      border: 1px solid transparent;
      border-right: 4px dotted #E4C865;
      border-right: 4px dotted var(--first-yellow);
    }

  .call__center__page .call__center__card .card__tittle {
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-family: var(--primary-font);
      color: #2C2E43;
      color: var(--dark-blue);
      font-size: 32px;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
  }

  .call__center__page .call__center__card .card__description {
      font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
      font-family: var(--description-font);
      color: #707070;
      color: var(--dark-gray);
      font-size: 18px;
      padding: 10px;
  }

  /* === get service button style === */ 
  .order__btn {
    --offset: 10px;
    --border-size: 2px;
    display: block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    position: relative;
    /* padding: 1.5em 3em; */
    -webkit-appearance: none;
            appearance: none;
    border: 0;
    background: transparent;
    color: #2C2E43 !important;
    color: var(--dark-blue) !important;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 0;
    transition: background 0.8s ease;
  }
  .order__btn:hover {
    background: #E4C865;
    background: var(--first-yellow);
  }
  .button__horizontal, .button__vertical {
    position: absolute;
    top: 0;
    top: var(--horizontal-offset, 0);
    right: 0;
    right: var(--vertical-offset, 0);
    bottom: 0;
    bottom: var(--horizontal-offset, 0);
    left: 0;
    left: var(--vertical-offset, 0);
    transition: -webkit-transform 0.8s ease;
    transition: transform 0.8s ease;
    transition: transform 0.8s ease, -webkit-transform 0.8s ease;
    will-change: transform;
  }
  .button__horizontal::before, .button__vertical::before {
    content: "";
    position: absolute;
    border: inherit;
  }
  .button__horizontal {
    --vertical-offset: calc(var(--offset) * -1);
    border-top: var(--border-size) solid currentcolor;
    border-bottom: var(--border-size) solid currentcolor;
  }
  .button__horizontal::before {
    top: calc(var(--vertical-offset) - var(--border-size));
    bottom: calc(var(--vertical-offset) - var(--border-size));
    left: calc(var(--vertical-offset) * -1);
    right: calc(var(--vertical-offset) * -1);
  }
  .order__btn:hover .button__horizontal {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  .button__vertical {
    --horizontal-offset: calc(var(--offset) * -1);
    border-left: var(--border-size) solid currentcolor;
    border-right: var(--border-size) solid currentcolor;
  }
  .button__vertical::before {
    top: calc(var(--horizontal-offset) * -1);
    bottom: calc(var(--horizontal-offset) * -1);
    left: calc(var(--horizontal-offset) - var(--border-size));
    right: calc(var(--horizontal-offset) - var(--border-size));
  }
  .order__btn:hover .button__vertical {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
/* === Get service button style end here === */

/* === shape decoration style start here === */
  .moon {
    position: absolute;
    width: 28%;
    height: 66%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #E4C865;
    box-shadow: 15px 15px 0 0 var(--first-yellow);
  }
  .moon2 {
    position: absolute;
    width: 25%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #2C2E43;
    box-shadow: 15px 15px 0 0 var(--dark-blue);
  }
  .moon3 {
    position: absolute;
    width: 35%;
    height: 64%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #707070;
    box-shadow: 15px 15px 0 0 var(--dark-gray);
  }
  .moon4 {
    position: absolute;
    width: 45%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 11px 0 0 #F6F7F8;
    box-shadow: 15px 11px 0 0 var(--light-gray);
  }
  .moon5 {
    position: absolute;
    width: 55%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 18px 10px 0 0 #E4C865;
    box-shadow: 18px 10px 0 0 var(--first-yellow);
  }
  .moon6 {
    position: absolute;
    top: 36%;
    width: 65%;
    height: 92%;
    border-radius: 50%;
    box-shadow: 15px 10px 0 0 #707070;
    box-shadow: 15px 10px 0 0 var(--dark-gray);
  }
  .moon7 {
    position: absolute;
    width: 75%;
    height: 72%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #F6F7F8;
    box-shadow: 15px 15px 0 0 var(--light-gray);
  }
  .moon8 {
    position: absolute;
    width: 85%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 11px 15px 0 0 #2C2E43;
    box-shadow: 11px 15px 0 0 var(--dark-blue);
  }
  .moon9 {
    position: absolute;
    top: 25%;
    width: 95%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 19px 5px 0 0 #E4C865;
    box-shadow: 19px 5px 0 0 var(--first-yellow);
  }
  .moon10 {
    position: absolute;
    top: 34%;
    width: 45%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 14px 20px 0 0 #2C2E43;
    box-shadow: 14px 20px 0 0 var(--dark-blue);
  }
/* === Shape decoration style end here === */


/* === It service page style end here === */

/* === style for smnaller device === */

@media(max-width:991px){

    .call__center__page .call__center__card .card__img {
        border-bottom: 4px dotted transparent;
        border-right: 4px dotted transparent;
      }

}

@media(max-width:767px){

    .call__center__page .call__center__card .card__img {
        border-bottom: 4px dotted #E4C865;
        border-bottom: 4px dotted var(--first-yellow);
        border-right: 4px dotted transparent;
        border-radius: 40px;
        transition: all 0.5 ease;
      }
    
}

/* === style for smaller device end here === */
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* === It service page style start here === */

  .call__center__page {
      background-color: #F6F7F8;
      background-color: var(--light-gray);
      width: 100%;
      height: auto;
  }

  .call__center__page .call__center__card .card {
      margin-top: 80px;
      margin-bottom: 80px;
      border-radius: 30px; 
     box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
    transition: all 0.5s ease;    
}

  .call__center__page .call__center__card .card__img {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 30px;
      border: 1px solid transparent;
      border-right: 4px dotted #E4C865;
      border-right: 4px dotted var(--first-yellow);
    }

  .call__center__page .call__center__card .card__tittle {
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-family: var(--primary-font);
      color: #2C2E43;
      color: var(--dark-blue);
      font-size: 32px;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
  }

  .call__center__page .call__center__card .card__description {
      font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
      font-family: var(--description-font);
      color: #707070;
      color: var(--dark-gray);
      font-size: 18px;
      padding: 10px;
  }

  /* === get service button style === */ 
  .order__btn {
    --offset: 10px;
    --border-size: 2px;
    display: block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    position: relative;
    /* padding: 1.5em 3em; */
    -webkit-appearance: none;
            appearance: none;
    border: 0;
    background: transparent;
    color: #2C2E43 !important;
    color: var(--dark-blue) !important;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 0;
    transition: background 0.8s ease;
  }
  .order__btn:hover {
    background: #E4C865;
    background: var(--first-yellow);
  }
  .button__horizontal, .button__vertical {
    position: absolute;
    top: 0;
    top: var(--horizontal-offset, 0);
    right: 0;
    right: var(--vertical-offset, 0);
    bottom: 0;
    bottom: var(--horizontal-offset, 0);
    left: 0;
    left: var(--vertical-offset, 0);
    transition: -webkit-transform 0.8s ease;
    transition: transform 0.8s ease;
    transition: transform 0.8s ease, -webkit-transform 0.8s ease;
    will-change: transform;
  }
  .button__horizontal::before, .button__vertical::before {
    content: "";
    position: absolute;
    border: inherit;
  }
  .button__horizontal {
    --vertical-offset: calc(var(--offset) * -1);
    border-top: var(--border-size) solid currentcolor;
    border-bottom: var(--border-size) solid currentcolor;
  }
  .button__horizontal::before {
    top: calc(var(--vertical-offset) - var(--border-size));
    bottom: calc(var(--vertical-offset) - var(--border-size));
    left: calc(var(--vertical-offset) * -1);
    right: calc(var(--vertical-offset) * -1);
  }
  .order__btn:hover .button__horizontal {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  .button__vertical {
    --horizontal-offset: calc(var(--offset) * -1);
    border-left: var(--border-size) solid currentcolor;
    border-right: var(--border-size) solid currentcolor;
  }
  .button__vertical::before {
    top: calc(var(--horizontal-offset) * -1);
    bottom: calc(var(--horizontal-offset) * -1);
    left: calc(var(--horizontal-offset) - var(--border-size));
    right: calc(var(--horizontal-offset) - var(--border-size));
  }
  .order__btn:hover .button__vertical {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
/* === Get service button style end here === */

/* === shape decoration style start here === */
  .moon {
    position: absolute;
    width: 28%;
    height: 66%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #E4C865;
    box-shadow: 15px 15px 0 0 var(--first-yellow);
  }
  .moon2 {
    position: absolute;
    width: 25%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #2C2E43;
    box-shadow: 15px 15px 0 0 var(--dark-blue);
  }
  .moon3 {
    position: absolute;
    width: 35%;
    height: 64%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #707070;
    box-shadow: 15px 15px 0 0 var(--dark-gray);
  }
  .moon4 {
    position: absolute;
    width: 45%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 11px 0 0 #F6F7F8;
    box-shadow: 15px 11px 0 0 var(--light-gray);
  }
  .moon5 {
    position: absolute;
    width: 55%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 18px 10px 0 0 #E4C865;
    box-shadow: 18px 10px 0 0 var(--first-yellow);
  }
  .moon6 {
    position: absolute;
    top: 36%;
    width: 65%;
    height: 92%;
    border-radius: 50%;
    box-shadow: 15px 10px 0 0 #707070;
    box-shadow: 15px 10px 0 0 var(--dark-gray);
  }
  .moon7 {
    position: absolute;
    width: 75%;
    height: 72%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #F6F7F8;
    box-shadow: 15px 15px 0 0 var(--light-gray);
  }
  .moon8 {
    position: absolute;
    width: 85%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 11px 15px 0 0 #2C2E43;
    box-shadow: 11px 15px 0 0 var(--dark-blue);
  }
  .moon9 {
    position: absolute;
    top: 25%;
    width: 95%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 19px 5px 0 0 #E4C865;
    box-shadow: 19px 5px 0 0 var(--first-yellow);
  }
  .moon10 {
    position: absolute;
    top: 34%;
    width: 45%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 14px 20px 0 0 #2C2E43;
    box-shadow: 14px 20px 0 0 var(--dark-blue);
  }
/* === Shape decoration style end here === */


/* === It service page style end here === */

/* === style for smnaller device === */

@media(max-width:991px){

    .call__center__page .call__center__card .card__img {
        border-bottom: 4px dotted transparent;
        border-right: 4px dotted transparent;
      }

}

@media(max-width:767px){

    .call__center__page .call__center__card .card__img {
        border-bottom: 4px dotted #E4C865;
        border-bottom: 4px dotted var(--first-yellow);
        border-right: 4px dotted transparent;
        border-radius: 40px;
        transition: all 0.5 ease;
      }
    
}

/* === style for smaller device end here === */
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* === It service page style start here === */

  .call__center__page {
      background-color: #F6F7F8;
      background-color: var(--light-gray);
      width: 100%;
      height: auto;
  }

  .call__center__page .call__center__card .card {
      margin-top: 80px;
      margin-bottom: 80px;
      border-radius: 30px; 
     box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
    transition: all 0.5s ease;    
}

  .call__center__page .call__center__card .card__img {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 30px;
      border: 1px solid transparent;
      border-right: 4px dotted #E4C865;
      border-right: 4px dotted var(--first-yellow);
    }

  .call__center__page .call__center__card .card__tittle {
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-family: var(--primary-font);
      color: #2C2E43;
      color: var(--dark-blue);
      font-size: 32px;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
  }

  .call__center__page .call__center__card .card__description {
      font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
      font-family: var(--description-font);
      color: #707070;
      color: var(--dark-gray);
      font-size: 18px;
      padding: 10px;
  }

  /* === get service button style === */ 
  .order__btn {
    --offset: 10px;
    --border-size: 2px;
    display: block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    position: relative;
    /* padding: 1.5em 3em; */
    -webkit-appearance: none;
            appearance: none;
    border: 0;
    background: transparent;
    color: #2C2E43 !important;
    color: var(--dark-blue) !important;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 0;
    transition: background 0.8s ease;
  }
  .order__btn:hover {
    background: #E4C865;
    background: var(--first-yellow);
  }
  .button__horizontal, .button__vertical {
    position: absolute;
    top: 0;
    top: var(--horizontal-offset, 0);
    right: 0;
    right: var(--vertical-offset, 0);
    bottom: 0;
    bottom: var(--horizontal-offset, 0);
    left: 0;
    left: var(--vertical-offset, 0);
    transition: -webkit-transform 0.8s ease;
    transition: transform 0.8s ease;
    transition: transform 0.8s ease, -webkit-transform 0.8s ease;
    will-change: transform;
  }
  .button__horizontal::before, .button__vertical::before {
    content: "";
    position: absolute;
    border: inherit;
  }
  .button__horizontal {
    --vertical-offset: calc(var(--offset) * -1);
    border-top: var(--border-size) solid currentcolor;
    border-bottom: var(--border-size) solid currentcolor;
  }
  .button__horizontal::before {
    top: calc(var(--vertical-offset) - var(--border-size));
    bottom: calc(var(--vertical-offset) - var(--border-size));
    left: calc(var(--vertical-offset) * -1);
    right: calc(var(--vertical-offset) * -1);
  }
  .order__btn:hover .button__horizontal {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  .button__vertical {
    --horizontal-offset: calc(var(--offset) * -1);
    border-left: var(--border-size) solid currentcolor;
    border-right: var(--border-size) solid currentcolor;
  }
  .button__vertical::before {
    top: calc(var(--horizontal-offset) * -1);
    bottom: calc(var(--horizontal-offset) * -1);
    left: calc(var(--horizontal-offset) - var(--border-size));
    right: calc(var(--horizontal-offset) - var(--border-size));
  }
  .order__btn:hover .button__vertical {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
/* === Get service button style end here === */

/* === shape decoration style start here === */
  .moon {
    position: absolute;
    width: 28%;
    height: 66%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #E4C865;
    box-shadow: 15px 15px 0 0 var(--first-yellow);
  }
  .moon2 {
    position: absolute;
    width: 25%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #2C2E43;
    box-shadow: 15px 15px 0 0 var(--dark-blue);
  }
  .moon3 {
    position: absolute;
    width: 35%;
    height: 64%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #707070;
    box-shadow: 15px 15px 0 0 var(--dark-gray);
  }
  .moon4 {
    position: absolute;
    width: 45%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 11px 0 0 #F6F7F8;
    box-shadow: 15px 11px 0 0 var(--light-gray);
  }
  .moon5 {
    position: absolute;
    width: 55%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 18px 10px 0 0 #E4C865;
    box-shadow: 18px 10px 0 0 var(--first-yellow);
  }
  .moon6 {
    position: absolute;
    top: 36%;
    width: 65%;
    height: 92%;
    border-radius: 50%;
    box-shadow: 15px 10px 0 0 #707070;
    box-shadow: 15px 10px 0 0 var(--dark-gray);
  }
  .moon7 {
    position: absolute;
    width: 75%;
    height: 72%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #F6F7F8;
    box-shadow: 15px 15px 0 0 var(--light-gray);
  }
  .moon8 {
    position: absolute;
    width: 85%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 11px 15px 0 0 #2C2E43;
    box-shadow: 11px 15px 0 0 var(--dark-blue);
  }
  .moon9 {
    position: absolute;
    top: 25%;
    width: 95%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 19px 5px 0 0 #E4C865;
    box-shadow: 19px 5px 0 0 var(--first-yellow);
  }
  .moon10 {
    position: absolute;
    top: 34%;
    width: 45%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 14px 20px 0 0 #2C2E43;
    box-shadow: 14px 20px 0 0 var(--dark-blue);
  }
/* === Shape decoration style end here === */


/* === It service page style end here === */

/* === style for smnaller device === */

@media(max-width:991px){

    .call__center__page .call__center__card .card__img {
        border-bottom: 4px dotted transparent;
        border-right: 4px dotted transparent;
      }

}

@media(max-width:767px){

    .call__center__page .call__center__card .card__img {
        border-bottom: 4px dotted #E4C865;
        border-bottom: 4px dotted var(--first-yellow);
        border-right: 4px dotted transparent;
        border-radius: 40px;
        transition: all 0.5 ease;
      }
    
}

/* === style for smaller device end here === */
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .gallery {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
    transition: all 0.5s ease;
  }

  .gallery .pics{
    -webkit-transition: all 0.5s ease;
    cursor: zoom-in;
    margin-bottom: 12px;
    transition: all 0.5s ease;
  }

  .gallery img {
    border-radius: 30px;
    box-shadow: 0 10px 12px rgb(22 33 39 / 35%);
  }

  .gallery .pics:hover {
    opacity: .8;
  }

  
  .triangle-topleft {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 100rem solid #E4C865;
    border-top: 100rem solid var(--first-yellow);
    z-index: -1;
    overflow: hidden;
    border-right: 400px solid transparent;
  }
  .triangle-topleft2 {
    position: absolute;
    width: 0;
    height: 0;
    right: 0 !important;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    border-top: 100rem solid #2C2E43;
    border-top: 100rem solid var(--dark-blue);
    z-index: -1;
    overflow: hidden;
    border-right: 400px solid transparent;
  }

  /* model style start here */

  .model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #2C2E43 , #F6F7F8 , #E4C865);
    background-image: linear-gradient(to right, var(--dark-blue) , var(--light-gray) , var(--first-yellow));
    transition: opacity .4s ease, visibility .4s ease, -webkit-transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    overflow: hidden;
    z-index: 999;
  }

  .model.open {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  .model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
  }

  .model .close__model {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: #2C2E43;
    background-color: var(--dark-blue);
    border: none;
    color: #F6F7F8;
    color: var(--light-gray);
    cursor: pointer;
    transition: all 0.5s ease-in;
  }

  .model .close__model:hover {
    background-color: #E4C865;
    background-color: var(--first-yellow);
    transition: all 0.5s ease;
  }

  /* model style end here */

  @media (max-width: 991px) {
    .gallery {
      -webkit-column-count: 2;
      column-count: 2;
      transition: all 0.5s ease;
    }
  }

  @media (max-width: 480px) {
    .gallery {
      -webkit-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
      column-width: 100%;
    }
  }
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .leadership__title{
    color: #E4C865;
    color: var(--first-yellow);
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .leadership__title h3{
    font-size: 63px;
  }

  .opus__team {
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-family: var(--primary-font);
      position: relative;
      padding-top: 80px;
      padding-bottom: 80px;
      width: 100%;
      overflow: hidden;
      height: 100% !important;
      min-height: 100vh !important;
  }

  .testimonials__card{
      position: relative;
      width: 24%;
      float: left;
      margin: 0.5%;
      transition: all 0.5s ease-in;
  }

  .testimonials__card .card:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    z-index: 1;
    transition: all 0.5s ease;
  }

  .ceo__card {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px; 
      box-shadow: 0px 0px 5px 5px rgba(116, 112, 112, 0.144);
  }

  /* Leadership card style  */
  .ceo__card img{
      border-radius: 40px;
      /* clip-path: circle(40%); */
      padding: 20px;
      /* -webkit-box-shadow: 0px 10px 10px 0px rgba(189,189,189,1);
     -moz-box-shadow: 0px 10px 10px 0px rgba(189,189,189,1);
     box-shadow: 0px 10px 10px 0px rgba(189,189,189,1); */
  }

  .ceo__row{
      margin-left: 27.5%;
      margin-right: 27.5%;
      width: 45%;
  }

  .immportant__row {
      margin: 20px;
  }

  .testimonials__card img {
      border-radius: 40px;
      /* clip-path: circle(40%); */
      width: 100%;
      height: 100%;
  }

  .testimonials__card .card {
      border: none;
      border-radius: 40px;
      margin-top: 10px;
      min-height: 350px;
      display: flex;
      justify-content: space-around;
      align-items: center; 
      box-shadow: 0px 0px 5px 5px rgba(116, 112, 112, 0.144);
      transition: all 0.5s ease;
  }

  .extend__btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: transparent;
    padding: 0px;
    font-size: 24px;
    transition: all 0.5s ease;
  }

  .extend__btn i{
      color: #2C2E43;
      color: var(--dark-blue);
      transition: all 0.5s ease;
  }

  .extend__btn i:hover {
      color: #E4C865;
      color: var(--first-yellow);
      -webkit-transform: rotate(39deg);
              transform: rotate(39deg);
      transition: all 0.5s ease;
  }

  .extend__user {
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-family: var(--primary-font);
      background-color: #F6F7F8;
      background-color: var(--light-gray);
      border-radius: 15px;
      border-bottom: 2px solid #E4C865;
      border-bottom: 2px solid var(--first-yellow);
      border-top: 2px solid #707070;
      border-top: 2px solid var(--dark-gray);
      padding: 0px;
      overflow: hidden;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px;
      -webkit-transform: scale(1);
              transform: scale(1);
      transition: all 0.5s ease;
  }

  /* .extend__user p {
    background: -webkit-linear-gradient(var(--dark-blue), var(--first-yellow));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
} */

@media(max-width:991px){
    
    .testimonials__card{
        width: 44%;
        transition: all 0.5s ease;
    }

    .ceo__row{
        margin-left: 0%;
        margin-right: 0%;
        width: 100%;
    }

}

@media (max-width:767px) { 

    .testimonials__card{
        width: 100%;
    }

}

:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  }
 /* shape decoration style start here */
 .moon {
    position: absolute;
    width: 28%;
    height: 66%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #E4C865;
    box-shadow: 15px 15px 0 0 var(--first-yellow);
  }
  .moon2 {
    position: absolute;
    width: 25%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #2C2E43;
    box-shadow: 15px 15px 0 0 var(--dark-blue);
  }
  .moon3 {
    position: absolute;
    width: 35%;
    height: 64%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #707070;
    box-shadow: 15px 15px 0 0 var(--dark-gray);
  }
  .moon4 {
    position: absolute;
    width: 45%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 11px 0 0 #F6F7F8;
    box-shadow: 15px 11px 0 0 var(--light-gray);
  }
  .moon5 {
    position: absolute;
    width: 55%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 18px 10px 0 0 #E4C865;
    box-shadow: 18px 10px 0 0 var(--first-yellow);
  }
  .moon6 {
    position: absolute;
    top: 36%;
    width: 65%;
    height: 92%;
    border-radius: 50%;
    box-shadow: 15px 10px 0 0 #707070;
    box-shadow: 15px 10px 0 0 var(--dark-gray);
  }
  .moon7 {
    position: absolute;
    width: 75%;
    height: 72%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #F6F7F8;
    box-shadow: 15px 15px 0 0 var(--light-gray);
  }
  .moon8 {
    position: absolute;
    width: 85%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 11px 15px 0 0 #2C2E43;
    box-shadow: 11px 15px 0 0 var(--dark-blue);
  }
  .moon9 {
    position: absolute;
    top: 25%;
    width: 95%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 19px 5px 0 0 #E4C865;
    box-shadow: 19px 5px 0 0 var(--first-yellow);
  }
  .moon10 {
    position: absolute;
    top: 34%;
    width: 45%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 14px 20px 0 0 #2C2E43;
    box-shadow: 14px 20px 0 0 var(--dark-blue);
  }
/* Shape decoration style end here */
  .contact__us__page .contactus__tittle {
    margin-top: 80px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
  }

  .contact__us__page .contactus__tittle h3 {
    font-size: 32px;
  }

  .contact__us__page .number__contact, 
  .contact__us__page .email__contact {
    margin-top: 20px;
    background-color: #E4C865;
    background-color: var(--first-yellow);
    color:#2C2E43;
    color:var(--dark-blue);
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 20px;
  }
 
  .MuiOutlinedInput-input {
    padding: 10px 10px !important;
  }

  .MuiPaper-elevation1 {
    border-radius: 30px !important;
    box-shadow: 0px 10px 10px 5px rgb(0 0 0 / 20%), 0px 0px 10px 5px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  }

  .container.contact__form__top_content {
    width: 60%;
  }

  .contact__us__page .contact__form {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 60%;
  }
  
  .contact__us__page .online__tittle {
    margin-top: 60px;
  }

  .contact__us__page .contact__form input {
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
    font-weight: 600;
    /* background-color: var(--light-gray);
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 40px;
    width: 60%; */
    color: #2C2E43;
    color: var(--dark-blue);
  }

  .contact__us__page .contact__form select {
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
    border: 1px solid #707070;
    border: 1px solid var(--dark-gray);
    font-weight: 600;
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    display: flex;
    color: #2C2E43;
    color: var(--dark-blue);
  }
  
  .contact__us__page .contact__form textarea {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    font-weight: 600;
    /* background-color: var(--light-gray);
    padding: 12px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 20px;
    width: 60%; */
    color: #2C2E43;
    color: var(--dark-blue);
  }

  .contact__us__page .apply__button {
    font-size: 22px;
    background-color: #E4C865;
    background-color: var(--first-yellow);
    border-radius: 20px;
    transition: all 0.5s ease;
  }

  .contact__us__page .apply__button:hover {
    opacity: 0.8;
    transition: all 0.5s ease;
  }

  .octagon {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px; 
    height: 50%; 
    background:#E4C865; 
    background:var(--first-yellow);
 }
  .octagon2 {
    position: absolute ;
    right: 0;
    bottom: -10%;
    width: 80px; 
    height: 50%; 
    background:#2C2E43; 
    background:var(--dark-blue);
 }

 @media(max-width:991px){
 
  .octagon {
    display: none;
 }
  .octagon2 {
    display: none;
 }

 .container.contact__form__top_content {
  width: 100% !important;
}

.contact__us__page .contact__form {
  width: 100%;
}
  
}
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  /* shape decoration style start here */
  .moon {
    position: absolute;
    width: 28%;
    height: 66%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #E4C865;
    box-shadow: 15px 15px 0 0 var(--first-yellow);
  }
  .moon2 {
    position: absolute;
    width: 25%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #2C2E43;
    box-shadow: 15px 15px 0 0 var(--dark-blue);
  }
  .moon3 {
    position: absolute;
    width: 35%;
    height: 64%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #707070;
    box-shadow: 15px 15px 0 0 var(--dark-gray);
  }
  .moon4 {
    position: absolute;
    width: 45%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 11px 0 0 #F6F7F8;
    box-shadow: 15px 11px 0 0 var(--light-gray);
  }
  .moon5 {
    position: absolute;
    width: 55%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 18px 10px 0 0 #E4C865;
    box-shadow: 18px 10px 0 0 var(--first-yellow);
  }
  .moon6 {
    position: absolute;
    top: 36%;
    width: 65%;
    height: 92%;
    border-radius: 50%;
    box-shadow: 15px 10px 0 0 #707070;
    box-shadow: 15px 10px 0 0 var(--dark-gray);
  }
  .moon7 {
    position: absolute;
    width: 75%;
    height: 72%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 #F6F7F8;
    box-shadow: 15px 15px 0 0 var(--light-gray);
  }
  .moon8 {
    position: absolute;
    width: 85%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 11px 15px 0 0 #2C2E43;
    box-shadow: 11px 15px 0 0 var(--dark-blue);
  }
  .moon9 {
    position: absolute;
    top: 25%;
    width: 95%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 19px 5px 0 0 #E4C865;
    box-shadow: 19px 5px 0 0 var(--first-yellow);
  }
  .moon10 {
    position: absolute;
    top: 34%;
    width: 45%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 14px 20px 0 0 #2C2E43;
    box-shadow: 14px 20px 0 0 var(--dark-blue);
  }
/* Shape decoration style end here */
  .contact__us__page .contactus__tittle {
    margin-top: 80px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
  }

  .contact__us__page .contactus__tittle h3 {
    font-size: 32px;
  }

  .contact__us__page .number__contact, 
  .contact__us__page .email__contact {
    margin-top: 20px;
    background-color: #E4C865;
    background-color: var(--first-yellow);
    color:#2C2E43;
    color:var(--dark-blue);
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 20px;
  }
 
  .MuiOutlinedInput-input {
    padding: 10px 10px !important;
  }

  .MuiPaper-elevation1 {
    border-radius: 30px !important;
    box-shadow: 0px 10px 10px 5px rgb(0 0 0 / 20%), 0px 0px 10px 5px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  }

  .container.contact__form__top_content {
    width: 60%;
  }

  .contact__us__page .contact__form {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 60%;
  }
  
  .contact__us__page .online__tittle {
    margin-top: 60px;
  }

  .contact__us__page .contact__form input {
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
    font-weight: 600;
    /* background-color: var(--light-gray);
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 40px;
    width: 60%; */
    color: #2C2E43;
    color: var(--dark-blue);
  }

  .contact__us__page .contact__form select {
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--description-font);
    border: 1px solid #707070;
    border: 1px solid var(--dark-gray);
    font-weight: 600;
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    display: flex;
    color: #2C2E43;
    color: var(--dark-blue);
  }
  
  .contact__us__page .contact__form textarea {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: var(--primary-font);
    font-weight: 600;
    /* background-color: var(--light-gray);
    padding: 12px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 20px;
    width: 60%; */
    color: #2C2E43;
    color: var(--dark-blue);
  }

  .contact__us__page .apply__button {
    font-size: 22px;
    background-color: #E4C865;
    background-color: var(--first-yellow);
    border-radius: 20px;
    transition: all 0.5s ease;
  }

  .contact__us__page .apply__button:hover {
    opacity: 0.8;
    transition: all 0.5s ease;
  }

  .octagon {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px; 
    height: 50%; 
    background:#E4C865; 
    background:var(--first-yellow);
 }
  .octagon2 {
    position: absolute ;
    right: 0;
    bottom: -20%;
    width: 80px; 
    height: 50%; 
    background:#2C2E43; 
    background:var(--dark-blue);
 }

 @media(max-width:991px){
 
  .octagon {
    display: none;
 }
  .octagon2 {
    display: none;
 }

 .container.contact__form__top_content {
  width: 100% !important;
}

.contact__us__page .contact__form {
  width: 100%;
}
  
}
:root {
  --dark-blue: #2C2E43;
  --first-yellow: #E4C865;
  --dark-gray: #707070;
  --light-gray: #F6F7F8;
  --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  background-color: #2C2E43;
  background-color: var(--dark-blue);
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  
  display: grid;
}

.darkmode__lightmode__switch {
  position: fixed !important;
  background-color: transparent;
  color: #fff;
  right: 5%;
  top: 1%;
  font-size: 28px;
  z-index: 9;
}

/* === scrollbar style === */
/* === width === */
::-webkit-scrollbar {
  width: 10px;
}

/* === Track === */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #E4C865;
  box-shadow: inset 0 0 5px var(--first-yellow); 
  border-radius: 40px;
}
 
/* === Handle === */
::-webkit-scrollbar-thumb {
  background: #2C2E43;
  background: var(--dark-blue); 
  border-radius: 40px;
}

/* === Handle on hover === */
::-webkit-scrollbar-thumb:hover {
  background:#707070;
  background:var(--dark-gray); 
}
/* === scrollbar style end here === */

.header__middle__container {
  width: 100%;
	margin-right:auto;
	margin-left:auto
}

.header__content {
  padding-left: 15px;
  padding-right: 15px;
}

img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #a3914f!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-family: var(--primary-font);
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #E4C865;
  color: var(--first-yellow);
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}

.top__navbar {
  width: 100%;
  background-color: #E4C865;
  background-color: var(--first-yellow);
  z-index: 1;
}

.top__navbar__menu {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.top__nav__menu__left {
  display: flex;
}

.top__navbar__menu li {
  padding: 10px;
  padding-bottom: 0px;
}


.header__middle__logo{
  padding-left: 55px;
  width: 20%;
  display: inline-block;
  float: left;
}
.header__middle__menus {
  width: 70%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  background-color: #2C2E43;
  background-color: var(--dark-blue);
  display: flex;
  width: 100%;
  float: left;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 7;
  transition: all 0.5s ease-in;
}

.header__middle.active {
  background-color: rgba(33, 35, 51, 0.295);
  top: -20px;
  transition: all 0.5s ease;
}

.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  z-index: 3;
  display: none;
  background-color: #fff;
  border: 1px solid transparent;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 120px;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 45%);
}


/* === style change web language button === */
.change__web__lang {
  background-color: #E4C865;
  background-color: var(--first-yellow);
  border: none;
}


@media (max-width:767px) { 
  body {
    width: 100% !important;
    overflow-x: hidden !important;
  }

  .header__middle .active {color: #E4C865 !important;color: var(--first-yellow) !important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: #f7e090 !important;
}
.menu-item .sub__menus li:hover a{
  color: #f7e090 !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){

  .darkmode__lightmode__switch {
    position: fixed !important;
    background-color: transparent;
    color: #fff;
    left: 0%;
    right: 85%;
    top: 1%;
    font-size: 28px;
    z-index: 9;
  }

.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #E4C865;background-color: var(--first-yellow); transition: all 0.3s ease;}
.main-nav .menubar__button {transition: all 0.5s ease;}
.main-nav .menu-item:hover > a {color: #f5dc81 !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #E4C865;
  background-color: var(--first-yellow);
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}

.sub__menus {
  z-index: 3 !important;
}

.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #2C2E43;
  background-color: var(--dark-blue);
  border-bottom: 3px solid #707070;
  border-bottom: 3px solid var(--dark-gray);
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #E4C865;color: var(--first-yellow);}
.main-nav  ul.main-menu {display: none;}
.main-nav .sub__menus__arrows:hover .sub__menus { margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}
:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  /* === About us page style start here === */
  
  .about__us__description {
      padding-top: 200px;
      margin-bottom: 100px;
  }

  .about__us__description .aboutus__desc__block {
      padding: 20px;
      border-radius: 40px;
      background-color: #F6F7F8;
      background-color: var(--light-gray); 
      box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
  }

  .about__us__description h3 {
      display: flex;
      justify-content: space-around;
      align-content: center;
      margin-bottom: 30px;
      font-size: 48px;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-family: var(--primary-font);
  }

  .about__us__description p {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-family: var(--description-font);
      font-size: 20px;
      letter-spacing: 1px;
      word-spacing: 3px;
  }

  .about__us__description .aboutus___images {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
  }

  .about__us__description .aboutus___images img {
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 400px;
      transition: all 1s ease;
  }

  .about__us__description .aboutus___images img:hover {
    cursor: crosshair;
      border-radius: 10px;
    transition: all 0.5s ease;
  }

  .aboutus__decoration {
    position: absolute;
    z-index: -1;
    height: 350px;
    width: 350px;
    border-radius: 100px;
    background-image: linear-gradient(to right, #2C2E43 , #F6F7F8);
    background-image: linear-gradient(to right, var(--dark-blue) , var(--light-gray));
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    /* transform-origin: 0 100%; */
  }

  .aboutus__decoration__2 {
    position: relative;
    float: right;
    margin-top: -200px;
    z-index: -1;
    height: 350px;
    width: 350px;
    border-radius: 100px;
    background-image: linear-gradient(to right, #F6F7F8 , #E4C865);
    background-image: linear-gradient(to right, var(--light-gray) , var(--first-yellow));
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    /* transform-origin: 0 100%; */
  }

  /* === Abotu us page style end here === */

  @media (max-width:767px) {
    .about__us__description .aboutus___images {
        width: 300px !important;
        margin-left: 25px;
        margin-right: 25px;
    }
  }
