:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .leadership__title{
    color: var(--first-yellow);
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .leadership__title h3{
    font-size: 63px;
  }

  .opus__team {
      font-family: var(--primary-font);
      position: relative;
      padding-top: 80px;
      padding-bottom: 80px;
      width: 100%;
      overflow: hidden;
      height: 100% !important;
      min-height: 100vh !important;
  }

  .testimonials__card{
      position: relative;
      width: 24%;
      float: left;
      margin: 0.5%;
      transition: all 0.5s ease-in;
  }

  .testimonials__card .card:hover {
    transform: scale(1.1);
    z-index: 1;
    transition: all 0.5s ease;
  }

  .ceo__card {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      -webkit-box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.4); 
      box-shadow: 0px 0px 5px 5px rgba(116, 112, 112, 0.144);
  }

  /* Leadership card style  */
  .ceo__card img{
      border-radius: 40px;
      /* clip-path: circle(40%); */
      padding: 20px;
      /* -webkit-box-shadow: 0px 10px 10px 0px rgba(189,189,189,1);
     -moz-box-shadow: 0px 10px 10px 0px rgba(189,189,189,1);
     box-shadow: 0px 10px 10px 0px rgba(189,189,189,1); */
  }

  .ceo__row{
      margin-left: 27.5%;
      margin-right: 27.5%;
      width: 45%;
  }

  .immportant__row {
      margin: 20px;
  }

  .testimonials__card img {
      border-radius: 40px;
      /* clip-path: circle(40%); */
      width: 100%;
      height: 100%;
  }

  .testimonials__card .card {
      border: none;
      border-radius: 40px;
      margin-top: 10px;
      min-height: 350px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      -webkit-box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.4); 
      box-shadow: 0px 0px 5px 5px rgba(116, 112, 112, 0.144);
      transition: all 0.5s ease;
  }

  .extend__btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: transparent;
    padding: 0px;
    font-size: 24px;
    transition: all 0.5s ease;
  }

  .extend__btn i{
      color: var(--dark-blue);
      transition: all 0.5s ease;
  }

  .extend__btn i:hover {
      color: var(--first-yellow);
      transform: rotate(39deg);
      transition: all 0.5s ease;
  }

  .extend__user {
      font-family: var(--primary-font);
      background-color: var(--light-gray);
      border-radius: 15px;
      border-bottom: 2px solid var(--first-yellow);
      border-top: 2px solid var(--dark-gray);
      padding: 0px;
      overflow: hidden;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px;
      transform: scale(1);
      transition: all 0.5s ease;
  }

  /* .extend__user p {
    background: -webkit-linear-gradient(var(--dark-blue), var(--first-yellow));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
} */

@media(max-width:991px){
    
    .testimonials__card{
        width: 44%;
        transition: all 0.5s ease;
    }

    .ceo__row{
        margin-left: 0%;
        margin-right: 0%;
        width: 100%;
    }

}

@media (max-width:767px) { 

    .testimonials__card{
        width: 100%;
    }

}
