:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

/* why chose us  style */

.why__us {
    margin-top: 80px;
    background-color: var(--light-gray);
}

.why__us .why__us__tittle {
    padding: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.why__us .why__us__tittle h3 {
    font-family: var(--primary-font);
    font-size: 40px;
}

.why__us .why__us__description {
    font-family: var(--description-font);
    padding-bottom: 20px;
}

.goto__leadership {
    padding-bottom: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.goto__leadership__btn {
    border: 4px solid transparent;
    width: 250px;
    border-radius: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    color: var(--light-gray);
    background-color: var(--first-yellow);
    display: flex;
    justify-content: center;
    text-align: center;
    transition: all .2s ease-in !important;
}

.goto__leadership__btn:hover{
    border: 4px dotted var(--first-yellow);
    background-color: var(--light-gray);
    transition: all .2s ease-in !important;
}

/* why chose us style end here */