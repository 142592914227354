:root {
    --dark-blue: #2C2E43;
    --first-yellow: #E4C865;
    --dark-gray: #707070;
    --light-gray: #F6F7F8;
    --primary-font:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    --description-font: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* === It service page style start here === */

  .call__center__page {
      background-color: var(--light-gray);
      width: 100%;
      height: auto;
  }

  .call__center__page .call__center__card .card {
      margin-top: 80px;
      margin-bottom: 80px;
      border-radius: 30px;
      -webkit-box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.4); 
     box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.21);
    transition: all 0.5s ease;    
}

  .call__center__page .call__center__card .card__img {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 30px;
      border: 1px solid transparent;
      border-right: 4px dotted var(--first-yellow);
    }

  .call__center__page .call__center__card .card__tittle {
      font-family: var(--primary-font);
      color: var(--dark-blue);
      font-size: 32px;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
  }

  .call__center__page .call__center__card .card__description {
      font-family: var(--description-font);
      color: var(--dark-gray);
      font-size: 18px;
      padding: 10px;
  }

  /* === get service button style === */ 
  .order__btn {
    --offset: 10px;
    --border-size: 2px;
    display: block;
    font-family: var(--primary-font);
    position: relative;
    /* padding: 1.5em 3em; */
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0;
    background: transparent;
    color: var(--dark-blue) !important;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 0;
    transition: background 0.8s ease;
  }
  .order__btn:hover {
    background: var(--first-yellow);
  }
  .button__horizontal, .button__vertical {
    position: absolute;
    top: var(--horizontal-offset, 0);
    right: var(--vertical-offset, 0);
    bottom: var(--horizontal-offset, 0);
    left: var(--vertical-offset, 0);
    transition: transform 0.8s ease;
    will-change: transform;
  }
  .button__horizontal::before, .button__vertical::before {
    content: "";
    position: absolute;
    border: inherit;
  }
  .button__horizontal {
    --vertical-offset: calc(var(--offset) * -1);
    border-top: var(--border-size) solid currentcolor;
    border-bottom: var(--border-size) solid currentcolor;
  }
  .button__horizontal::before {
    top: calc(var(--vertical-offset) - var(--border-size));
    bottom: calc(var(--vertical-offset) - var(--border-size));
    left: calc(var(--vertical-offset) * -1);
    right: calc(var(--vertical-offset) * -1);
  }
  .order__btn:hover .button__horizontal {
    transform: scaleX(0);
  }
  .button__vertical {
    --horizontal-offset: calc(var(--offset) * -1);
    border-left: var(--border-size) solid currentcolor;
    border-right: var(--border-size) solid currentcolor;
  }
  .button__vertical::before {
    top: calc(var(--horizontal-offset) * -1);
    bottom: calc(var(--horizontal-offset) * -1);
    left: calc(var(--horizontal-offset) - var(--border-size));
    right: calc(var(--horizontal-offset) - var(--border-size));
  }
  .order__btn:hover .button__vertical {
    transform: scaleY(0);
  }
/* === Get service button style end here === */

/* === shape decoration style start here === */
  .moon {
    position: absolute;
    width: 28%;
    height: 66%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 var(--first-yellow);
  }
  .moon2 {
    position: absolute;
    width: 25%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 var(--dark-blue);
  }
  .moon3 {
    position: absolute;
    width: 35%;
    height: 64%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 var(--dark-gray);
  }
  .moon4 {
    position: absolute;
    width: 45%;
    height: 65%;
    border-radius: 50%;
    box-shadow: 15px 11px 0 0 var(--light-gray);
  }
  .moon5 {
    position: absolute;
    width: 55%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 18px 10px 0 0 var(--first-yellow);
  }
  .moon6 {
    position: absolute;
    top: 36%;
    width: 65%;
    height: 92%;
    border-radius: 50%;
    box-shadow: 15px 10px 0 0 var(--dark-gray);
  }
  .moon7 {
    position: absolute;
    width: 75%;
    height: 72%;
    border-radius: 50%;
    box-shadow: 15px 15px 0 0 var(--light-gray);
  }
  .moon8 {
    position: absolute;
    width: 85%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 11px 15px 0 0 var(--dark-blue);
  }
  .moon9 {
    position: absolute;
    top: 25%;
    width: 95%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 19px 5px 0 0 var(--first-yellow);
  }
  .moon10 {
    position: absolute;
    top: 34%;
    width: 45%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 14px 20px 0 0 var(--dark-blue);
  }
/* === Shape decoration style end here === */


/* === It service page style end here === */

/* === style for smnaller device === */

@media(max-width:991px){

    .call__center__page .call__center__card .card__img {
        border-bottom: 4px dotted transparent;
        border-right: 4px dotted transparent;
      }

}

@media(max-width:767px){

    .call__center__page .call__center__card .card__img {
        border-bottom: 4px dotted var(--first-yellow);
        border-right: 4px dotted transparent;
        border-radius: 40px;
        transition: all 0.5 ease;
      }
    
}

/* === style for smaller device end here === */